import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 12px;
  border-radius: 4px;
`;

export const ProductWrapper = styled.div`
  display: flex;
  align-content: center;
`;

export const ProductImage = styled.div`
  max-width: 92px;
  max-height: 92px;
  padding: 8px;
  border: 1px solid #ccd2e3;
  border-radius: 4px;
  position: relative;

  img {
    width: 100%;
  }

  @media (max-width: 500px) {
    max-width: 60px;
    max-height: 60px;
  }
`;

export const QuantityCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(30%, -30%);
  border-radius: 50%;
  width: 24px;
  height: 24px;
  background: #001c72;
  color: #ffffff;
  opacity: 0.8;
  font-weight: 700;
  font-size: 16px;
`;

export const ProductInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 20px;
`;

export const Text = styled.div`
  font-weight: 400;
  font-size: 16px;
  margin: 3px 0;
`;
